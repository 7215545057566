import React, { useEffect, useMemo, useRef, useState } from "react";
import logo from "Assets/img/logo.png";
import "Assets/app/app.css";

const WidgetDefaultContent: React.FC = (props) => {

    return <React.Fragment>
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="logo" />
                {/* <p>
                    No Programs
                </p> */}
                {/* <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a> */}
            </header>
        </div>
    </React.Fragment>
}

export default WidgetDefaultContent