import { ExampleLayout } from "Types/example/layout";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import lodash_ from "lodash";

interface Tprops {
    id?: string,
    objectReview?: {
        content: string,
        created_by?: string,
        created_time?: number,
        description?: string,
        group_id?: string,
        layout: string,
        name?: string,
        play_time?: number,
        resolution?: string,
        schedules?: number,
        status?: number,
        template?: string,
        updated_by?: string,
        updated_time?: number,
        __v?: number,
        _id?: string
    },
    visible?: boolean,
    active?: boolean
}

type TObjectLayout = typeof ExampleLayout.objects[0];

const WidgetPreviewSvg: React.FC<Tprops> = (props) => {
    const { objectReview, active } = props;
    const [loading, setLoading] = useState(false);
    const refTimerSlider = useRef<any>({});
    const refVideoBlobs = useRef<any>({});

    // svg content string
    const objectContent = useMemo(() => {
        return (objectReview?.content.toString() || "")
    }, [objectReview])
    const objectLayoutStr = useMemo(() => {
        return objectReview?.layout.toString() || ""
    }, [objectReview])

    // tất cả các object của layout
    const objectLayout: typeof ExampleLayout | null = useMemo(() => {
        return objectContent && JSON.parse(objectLayoutStr)
    }, [objectLayoutStr])

    // lấy các object thuộc dạng chạy slide
    const objectsLayoutSlider: TObjectLayout[] = useMemo(() => {
        return objectLayout ? objectLayout.objects.filter((item) => !['coverTime', 'coverText'].includes(item.name) && item.box.length) : []
    }, [objectLayout])

    // các object đồng hồ
    const objectLayoutTimer: TObjectLayout[] = useMemo(() => {
        return objectLayout ? objectLayout.objects.filter((item) => ['coverTime'].includes(item.name)) : []
    }, [objectLayout])

    // các object chữ chạy
    const objectLayoutText: TObjectLayout[] = useMemo(() => {
        return objectLayout ? objectLayout.objects.filter((item) => ['coverText'].includes(item.name) && item.box.length) : []
    }, [objectLayout])

    // clear all effect timeout
    // useEffect(() => {

    // }, [ objectReview ])

    // run style object
    useEffect(() => {
        // muteAllVideos()
        if (objectsLayoutSlider.length && active !== false) {
            AddStylesSlider(objectsLayoutSlider);
            AddActionsSlider(objectsLayoutSlider);
        }
    }, [active, objectsLayoutSlider])

    useEffect(() => {
        if (objectLayoutTimer.length && active !== false) AddTimer(objectLayoutTimer)
    }, [active, objectLayoutTimer])

    useEffect(() => {
        if (objectLayoutText.length && active !== false) AddText(objectLayoutText)
    }, [active, objectLayoutText])

    useEffect(() => {
        // console.log('refTimerSlider.current', refTimerSlider.current)
    }, [refTimerSlider.current])

    // end run style object

    useEffect(() => {
        // active window
        document.addEventListener("visibilitychange", () => {
            // console.log(document.hidden)
        });

        return () => {
            muteAllVideos();
            console.log("return component", props.id)
            Object.keys((refTimerSlider.current)).map((key) => {
                clearTimeout(refTimerSlider.current[key])
            })
        }
    }, [])

    const muteAllVideos = () => {
        const videos: any = props.id ? document.querySelectorAll(`.program-${props.id} video`) : document.querySelectorAll(`video`);
        videos.forEach((video: HTMLVideoElement) => {
            video.pause();
            video.currentTime = 0;
            video.muted = true;
        });
    };

    const PlayVideo = (video: HTMLVideoElement, sound: boolean) => {
        video.currentTime = 0;

        video.play().then(() => {
            if (video.muted !== !sound) {
                video.muted = !sound
            }
        }).catch((err) => {
            // console.log("Error play", err)
        })
    }

    const PauseVideo = (video: HTMLVideoElement) => {
        video.pause();
        // video.muted = true;
    }

    // add style layout slide
    const AddStylesSlider = (objects: TObjectLayout[]) => {
        // console.log("add style slider", objectsLayoutSlider);

        objects.forEach((object, i) => {
            // add thẻ style -> head
            let styleElement = document.querySelector(`#${GetClassStyleObject(object)}`);
            if (!styleElement) {
                styleElement = document.createElement('style');
                styleElement.id = `${GetClassStyleObject(object)}`;
            }
            document.head.appendChild(styleElement);

            let withObject = object.width / object.scaleSvg;

            // add style animation main
            styleElement.innerHTML = `
                .${object.id}{
                    position: absolute;
                }
                .${object.id}-slideIn {
                    transition: all ease-in-out 0s;
                    transform: translateX(0)!important;
                    z-index: ${object.box.length + 1}!important
                }
                .${object.id}-slideOut {
                    transition: all ease-in-out 0s;
                    transform: translateX(100%)!important;
                }
            `

            // add style to box
            object.box.forEach((box, i) => {
                let element = document.querySelectorAll(`.${object.id}`)[i];

                if (element) {
                    (element as any).style.zIndex = (object.box.length - i);
                    (element as any).style.transform = `translateX(-100%)`;
                }
            })
        })
    }

    const GetClassStyleObject = (item: any) => {
        return `style-${item.id}`
    }

    const AddActionsSlider = (objects: TObjectLayout[]) => {
        // muted all video
        muteAllVideos();

        objects.forEach((object) => {
            let slide_index = 1,
                slide_timeout = object.box[0].play_time;

            let boxs_element = document.querySelectorAll(`.${object.id}`);
            let slideOut = `${object.id}-slideOut`;
            let slideIn = `${object.id}-slideIn`;
            let withObject = object.width / object.scaleSvg;
            let key_timeout_effect = `${object.id}_effect_slideout`;

            if (refTimerSlider.current[object.id]) clearTimeout(refTimerSlider.current[object.id]);
            if (refTimerSlider.current[key_timeout_effect]) clearTimeout(refTimerSlider.current[key_timeout_effect]);

            let p = () => {
                try {
                    // console.log('slide_timeout', object.id, slide_timeout)

                    let box_current = object.box[slide_index];
                    let slide_next = slide_index + 1 > object.box.length - 1 ? 0 : slide_index + 1;
                    let slide_prev = slide_index - 1 < 0 ? object.box.length - 1 : slide_index - 1;

                    // load video tiếp theo
                    if(boxs_element[slide_next] instanceof HTMLVideoElement && slide_next != slide_index){
                        // console.log("load video next")
                        // PlayVideo(boxs_element[slide_next] as HTMLVideoElement, false)
                        // setTimeout(() => {
                        //     PauseVideo(boxs_element[slide_next] as HTMLVideoElement)
                        // }, 1000)
                    }

                    // effetc time
                    (boxs_element[slide_prev] as any).style.transitionDuration = `${box_current.effect_time}s`;
                    (boxs_element[slide_index] as any).style.transitionDuration = `${box_current.effect_time}s`;

                    // slide in
                    boxs_element[slide_prev].classList.remove(slideIn);
                    boxs_element[slide_prev].classList.add(slideOut);

                    boxs_element[slide_index].classList.remove(slideOut);
                    boxs_element[slide_index].classList.add(slideIn);

                    // clear effect slide next
                    refTimerSlider.current[key_timeout_effect] = setTimeout(() => {
                        boxs_element.forEach((element) => {
                            (element as any).style.transitionDuration = `0s`;
                            element.classList.remove(slideOut);
                        })
                    }, box_current.effect_time * 1000)

                    const p_ = () => {
                        // clear effect and slide out
                        slide_timeout = box_current.play_time + box_current.effect_time;
                        slide_index = slide_index + 1 > boxs_element.length - 1 ? 0 : slide_index + 1;

                        refTimerSlider.current[object.id] = setTimeout(p, slide_timeout * 1000)
                    }

                    // kiểm tra item trước có phải là video -> pause
                    if (boxs_element[slide_prev] instanceof HTMLVideoElement) {
                        PauseVideo((boxs_element[slide_prev] as HTMLVideoElement))
                    }

                    // kiểm tra item là video -> play, mute
                    if (boxs_element[slide_index] instanceof HTMLVideoElement) {
                        refTimerSlider.current[object.id] = setTimeout(() => {
                            PlayVideo(boxs_element[slide_index] as any, box_current.sound ? true : false)
                            p_();
                        }, box_current.effect_time * 1000)
                    } else {
                        p_()
                    }
                } catch (err) {
                    console.log(err)
                    clearTimeout(refTimerSlider.current[object.id])
                }
            }

            // active element đầu tiền
            boxs_element[0].classList.add(slideIn);
            if (boxs_element[0] instanceof HTMLVideoElement) {
                PlayVideo(boxs_element[0] as any, object.box[0].sound ? true : false)

                // load video tiếp theo
                // if(boxs_element[1] instanceof HTMLVideoElement){
                //     PlayVideo(boxs_element[1] as HTMLVideoElement, false)
                //     setTimeout(() => {
                //         PauseVideo(boxs_element[1] as HTMLVideoElement)
                //     }, 1000)
                // }
            }

            // nếu nhiều element cho chạy slide
            if (object.box.length > 1) refTimerSlider.current[object.id] = setTimeout(p, slide_timeout * 1000)

        })
    }

    // layout đồng hồ
    const AddTimer = (objects: TObjectLayout[]) => {
        objects.forEach((object) => {
            if (refTimerSlider.current[object.id]) clearTimeout(refTimerSlider.current[object.id]);

            let p = () => {
                let element = document.querySelector(`.${object.id}`);
                if (element) {
                    element.textContent = moment().format("HH:mm:ss")
                }
                refTimerSlider.current[object.id] = setTimeout(p, 1000)
            }

            refTimerSlider.current[object.id] = setTimeout(p, 1000)
        })
    }

    // layout text slide
    const AddText = (objects: TObjectLayout[]) => {
        objects.forEach((object) => {
            const withObject = object.width / object.scaleSvg;
            const element = document.getElementById(`${object.id}`) as HTMLElement;

            // add style head
            let styleElement = document.querySelector(`#${GetClassStyleObject(object)}`);
            if (!styleElement) {
                styleElement = document.createElement('style');
                styleElement.id = `${GetClassStyleObject(object)}`;
            }
            document.head.appendChild(styleElement);

            if (element) {
                if (object.box[0]?.time === 0) {
                    element.style.width = '100%'
                    element.style.textAlign = 'center';
                }
                element.style.animation = `move${object.id} ${object.box[0].time || 0}s linear infinite`
                /* Create the animation */
                styleElement.innerHTML = `
                    @keyframes move${object.id} {
                        0% {
                            transform: translateX(${withObject}px);
                        }
                        100% {
                            transform: translateX(-105%);
                        }
                    }
                `
            }
        })
    }

    return <>
        <div style={{ display: `${loading ? 'block' : 'none'}` }} className="loading-screen bg-primary"></div>
        <div
            style={{ position: "fixed", display: "flex", opacity: loading ? 0 : 1}}
            dangerouslySetInnerHTML={{ __html: objectContent }}
        />
    </>
}

export default WidgetPreviewSvg