import React, { useEffect, useMemo, useRef, useState } from "react";
import logo from "Assets/img/logo.png";
import "Assets/app/app.css";

const ProgramDownLoading: React.FC = (props) => {

    return <React.Fragment>
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="logo" />
                <p>
                    Programs Downloading
                </p>
            </header>
        </div>
    </React.Fragment>
}

export default ProgramDownLoading