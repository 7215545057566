import { ExamplePlayerComposer } from "Types/example/player";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import WidgetPreviewSvg from "./previewSvg";
import cx from "classnames";
import { postMessageApp, postSyncMessageApp } from "Utils";
import { APP_METHODS } from "Utils/contants";
import ProgramDownLoading from "./programDownloadStatus";

type TPreview = {
    // programs: typeof ExamplePlayerComposer.programs
    programs: string
}

const WidgetPreviewContent: React.FC<TPreview> = (props) => {
    const [indexLoop, setindexLoop] = useState(0);
    const timerPlay = useRef<any>();
    const [ ProgramRun, SetProgramRun ] = useState<typeof ExamplePlayerComposer.programs[0] | undefined>()

    const Programs = useMemo(() => {
        try{
            return props.programs ? JSON.parse(props.programs) as typeof ExamplePlayerComposer.programs : []
        } catch(err){
            return []
        }
    }, [props.programs])

    const ProgramId = useMemo(() => {
        let result = Programs[indexLoop]?._id;
        return result
    }, [Programs, indexLoop])

    const Program = useMemo(() => {
        let result = Programs.find((item) => item._id === ProgramId && ProgramId);
        return result
    }, [Programs, ProgramId])

    useEffect(() => {
        try{
            if (Program) {
                if (timerPlay.current) clearInterval(timerPlay.current);
    
                let start_time = moment().unix();
    
                timerPlay.current = setInterval(() => {
                    // console.log("check play time", Program.play_time);
                    if (moment().add(-(Program.play_time || 30), "seconds").unix() >= start_time) {
                        setindexLoop(!Programs[indexLoop + 1] ? 0 : indexLoop + 1);
                        clearInterval(timerPlay.current);
                    }
                }, 1000)
            } else {
                if (Programs.length > 0) {
                    setindexLoop(0)
                }
            }
        } catch(err){

        }
    }, [Program])

    useEffect(() => {
        if(Program){
            _loadProgram(Program)
        }
    }, [ Program ])

    const _loadProgram = (program_: any) => {
        try{
            if(program_){
                postSyncMessageApp({
                    type: APP_METHODS.getMedia,
                    data: program_.resources
                }, (response: any) => {
                    let content = program_.content;
                    for(let i = 0; i < response.data.length; i++){
                        let item = response.data[i];
                        if(item.url){
                            content = content.replace(new RegExp(item.url, "gi"), item.value)
                        }
                    }
                    program_.content = content;
                    SetProgramRun(program_)
                })
                // SetProgramRun(program_)
            }
        } catch(err){

        }
    }

    return Programs.length == 0 ? null : <React.Fragment>
        {
            ProgramRun ? <div className={cx("app-player-program", `program-${ProgramRun._id}`, {
                active: true
            })} key={ProgramRun._id}>
                <WidgetPreviewSvg
                    objectReview={{
                        content: ProgramRun.content,
                        layout: ProgramRun.layout as any
                    }}
                    id={ProgramRun._id}
                />
            </div> : <ProgramDownLoading />
        }
    </React.Fragment>
}

export default WidgetPreviewContent