import { AppPreviewPage } from 'Pages';
import React, { useEffect } from 'react';

const App: React.FC = () => {
  return (
    <React.Fragment>
        <AppPreviewPage />
    </React.Fragment>
  );
}

export default App;
