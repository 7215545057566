import moment from "moment";

export const ConfigApp = {
    baseUrl: process.env.REACT_APP_API,
    api: {
        user: {
            login: "/api/v1/user/login"
        },
        monitor: {
            list: "/api/v1/monitor",
            save: "/api/v1/monitor",
            delete: "/api/v1/monitor",
            setCompany: "/api/v1/manager/monitor/company"
        },
        player: {
            list: "/api/v1/monitor/player",
            log: "/api/v1/monitor/log",
            updateCampaign: "/api/v1/monitor/campaign"
        },
        campaign: {
            list: "/api/v1/program",
            previewContent:"/api/v1/program/preview/content",
            create: "/api/v1/program",
            update: "/api/v1/program",
            saveTemplate:"/api/v1/program/template",
            delete: "/api/v1/program",
            select: "/api/v1/program/select"
        },
        resource: {
            list: "/api/v1/resource",
            upload: "/api/v1/resource/upload",
            update: "/api/v1/resource",
            delete: "/api/v1/resource"
        },
        schedule: {
            list: '/api/v1/campaign/schedule',
            save: '/api/v1/campaign/schedule',
            delete: '/api/v1/campaign/schedule'
        },
        group: {
            list: '/api/v1/group',
            delete: '/api/v1/group',
            create: '/api/v1/group',
            update: '/api/v1/group'
        },
        company: {
            list: '/api/v1/manager/company',
            delete: '/api/v1/manager/company',
            create: '/api/v1/manager/company',
            update: '/api/v1/manager/company'
        },
        accounts: {
            create: '/api/v1/manager/account',
            delete: '/api/v1/manager/account',
            update: '/api/v1/manager/account',
            list: '/api/v1/manager/account'
        },
        auth: {
            detail: '/api/v1/user/login',
            update: '/api/v1/user/update',
            change_pw: '/api/v1/user/change_pw'
        }
    },
    nameAuth: "auth",
    nameLocale: "locale",
    apiKey: "authorization",
    auditLog: "auditlog",
    urlSocket: process.env.REACT_APP_SOCKET as string || window.location.origin,
    namePlayerId: "PLAYER_ID",
    storagePlayerName: "PLAYER_NAME",
    namePlayerPrograms: "PLAYER_PROGRAMS",
}

export const APP_METHODS = {
    getFreeStorage: "getFreeStorage",
    getNetInfo: "getNetInfo",
    getDimensions: "getDimensions",
    resFreeStorage: "freeStorage",
    resNetInfo: "netInfo",
    resDimensions: "dimensions",
    getMedia: "getMedia"
}