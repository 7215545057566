import { ExamplePlayer, ExamplePlayerComposer } from "Types/example/player";
import { APP_METHODS, ConfigApp } from "Utils/contants";
import { AppSocket, SocketMethods } from "Utils/websocket";
import moment, { weekdaysShort } from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import WidgetPreviewContent from "./Widgets/previewContent";
import { GetPlayerId, GetPlayerProgram, SetPlayerProgram, getValuesFormData, postMessageApp } from "Utils";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import BySize from "byte-size";
import WidgetDefaultContent from "./Widgets/default";
import { useLocation } from 'react-router-dom';

type TPlayerComposer = typeof ExamplePlayerComposer | null;
type TPlayerCampaign = typeof ExamplePlayerComposer.programs | [];

const AppPreviewPage: React.FC = (props) => {
    const searchParams = new URLSearchParams(window ? window.location.search : "");
    const [playerId, setStatePlayerId] = useState(searchParams.get("player_id") || GetPlayerId());

    const timerGetComposer = useRef<any>();
    const [programs, setPrograms] = useState<TPlayerCampaign>(GetPlayerProgram());
    const appInfo = useRef<{
        storage: string
    }>();
    const [player, setPlayer] = useState<typeof ExamplePlayer | null>(null);
    const refSocket = useRef<any>();
    const [ ProgramsStr, setProgramsStr ] = useState("")

    const InfoPlayer = useCallback(() => {
        let data: any
        if(window){
            data = {
                "playerId": playerId,
                "userAgent": window.navigator.userAgent,
                "platform": window.navigator.platform,
                "language": window.navigator.language,
                "appCodeName": window.navigator.appCodeName,
                "deviceMemory": (window.navigator as any).deviceMemory,
                "vendor": window.navigator.vendor,
                "connection": {
                    effectiveType: (window.navigator as any).connection?.effectiveType
                },
                "screen": {
                    width: window.screen.width,
                    height: window.screen.height
                }
            }
        } else {
            data = {
                "playerId": playerId
            }
        }

        if (appInfo.current) {
            data = Object.assign(appInfo.current, data)
        }

        return data

    }, [playerId, appInfo.current])

    const GetInfoApp = () => {
        postMessageApp(JSON.stringify({
            req_id: Date.now(),
            type: APP_METHODS.getFreeStorage
        }))
    }

    useEffect(() => {
        GetInfoApp()
        setInterval(() => {
            GetInfoApp()
        }, 10000)
    }, [])

    useEffect(() => {
        try {
            if (playerId) {
                const socket = new AppSocket(`${ConfigApp.urlSocket}/ws?player_id=${playerId}`);
                refSocket.current = socket;

                window.onmessage = (ev) => {
                    try {
                        let event_data = typeof ev.data == "string" ? JSON.parse(ev.data) : ev.data;

                        switch (event_data.type) {
                            case SocketMethods.SOCKET_OPEN:
                                // đăng ký player
                                socket.send(JSON.stringify({
                                    event: SocketMethods.SUBSCRIBER_PLAYER,
                                    data: InfoPlayer()
                                }))
                                break
                            case SocketMethods.SOCKET_MESSAGE:
                                let message = JSON.parse(event_data.data);

                                switch (message.event) {
                                    // server gửi trạng thái đăng ký player
                                    case SocketMethods.SUBSCRIBER_PLAYER:
                                        setPlayer(message.data);

                                        if (timerGetComposer.current) clearTimeout(timerGetComposer.current);
                                        let p = () => {
                                            try {
                                                socket.send(JSON.stringify({
                                                    event: SocketMethods.COMPOSER_PLAYER,
                                                    data: InfoPlayer()
                                                }))

                                                timerGetComposer.current = setTimeout(p, 10000)

                                            } catch (err) {
                                                console.log(err)
                                            }
                                        };

                                        p();

                                        break
                                    case SocketMethods.POST_DATA_PLAYER:
                                        setPlayer(message.data);
                                        break
                                    // lấy kịch bản chạy
                                    case SocketMethods.COMPOSER_PLAYER:
                                        let data: TPlayerComposer = message.data;

                                        try{
                                            setPrograms(data && data.programs ? data.programs : [])
                                        } catch(err){

                                        }

                                        break
                                    case SocketMethods.RELOAD:
                                        window.location.reload();
                                        break
                                    default:
                                        break
                                }
                                break
                            case APP_METHODS.getFreeStorage:
                                break
                            case APP_METHODS.getNetInfo:
                                break
                            case APP_METHODS.getDimensions:
                                break
                            case APP_METHODS.resFreeStorage:
                                appInfo.current = Object.assign({
                                    storage: BySize(event_data.storage).toString()
                                }, appInfo.current)
                                break
                            case APP_METHODS.resNetInfo:
                                break
                            case APP_METHODS.resDimensions:
                                break
                            default:
                                break
                        }
                    } catch (err) {

                    }
                }
            }
        } catch (err: any) {
            console.log(err);
        }
    }, [playerId])

    const GetUnixTime = (time: string) => {
        return moment(`${moment().format("YYYY-MM-DD")} ${time}`).unix();
    }

    // chiến dịch đã set time
    const ProgramTimer = useMemo(() => {
        return programs.filter((item) => item.schedules.find((item_) =>
            (item_.start_date <= moment().format("YYYY-MM-DD") && item_.end_date >= moment().format("YYYY-MM-DD")) &&
            (GetUnixTime(item_.times[0]) <= moment().unix() && GetUnixTime(item_.times[1]) >= moment().unix()) &&
            item_.weekdays.includes(weekdaysShort()[moment().weekday()])
        ))
    }, [programs])

    // chiến dịch chưa set time
    const ProgramLoop = useMemo(() => {
        return programs.filter((item) => item.schedules.length == 0)
    }, [programs])

    // ưu tiên các chiến dịch đã set time
    useEffect(() => {
        try{
            let res = (ProgramTimer.length > 0 ? ProgramTimer : ProgramLoop).filter((item) => item)
            if(res.length > 0){
                setProgramsStr(JSON.stringify(res))
            } else {
                setProgramsStr("")
            }
        } catch(err){

        }
    }, [ ProgramTimer, ProgramLoop ])

    useEffect(() => {
        SetPlayerProgram(programs)
    }, [ programs ])

    useEffect(() => {
        if(player){
            localStorage.setItem("programs", JSON.stringify(programs))
            localStorage.setItem("player", JSON.stringify(player))
        }
    }, [ programs, player])

    useEffect(() => {
        if(!window.navigator.onLine && !player){
            try{
                setPrograms(localStorage.getItem("programs") ? JSON.parse(localStorage.getItem("programs") as string) : [])
                setPlayer(localStorage.getItem("player") ? JSON.parse(localStorage.getItem("player") as string) : null)
            } catch(err){

            }
        }
    }, [])

    return player !== null && player.player_name ? <React.Fragment>
            <div id="app-player">
                {
                    ProgramsStr ? <WidgetPreviewContent
                        programs={ProgramsStr}
                    /> : <WidgetDefaultContent />
                }
            </div>
        </React.Fragment> : <React.Fragment>
        <div id="app-player" >
            <div className="widget-block" style={{ height: "100%", display: "flex", alignContent: "center", alignItems: "center" }}>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        let values = getValuesFormData(e.target as any);

                        let data = Object.assign(InfoPlayer(), {
                            playerName: values.player_name
                        })

                        refSocket.current?.send(JSON.stringify({
                            event: SocketMethods.POST_DATA_PLAYER,
                            data: data
                        }))
                    }}
                    className="form-setting"
                >
                    <div className="widget-head card-header">
                        <h5>Thiết lập cơ bản</h5>
                    </div>
                    <div className="widget-content">
                        <div className="widget-box" style={{
                            padding: "10px"
                        }}>
                            <Label>Player ID</Label>
                            <FormGroup>
                                <Input value={player?.player_id} disabled />
                            </FormGroup>

                            <Label>Player</Label>
                            <FormGroup>
                                <Input placeholder="Nhập tên player.." name="player_name" />
                            </FormGroup>
                        </div>

                        <div className="widget-bottom"
                            style={{
                                padding: "10px",
                                textAlign: "right"
                            }}
                        >
                            <Button color="primary" type='submit' disabled={!player}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </React.Fragment>
}

export default AppPreviewPage