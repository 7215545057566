const SocketMethods = {
    SOCKET_OPEN: "SOCKET_OPEN",
    SOCKET_MESSAGE: "SOCKET_MESSAGE",
    SOCKET_CLOSE: "SOCKET_CLOSE",
    PING: "ping",
    SUBSCRIBER_PLAYER: "SUBSCRIBER_PLAYER",
    POST_DATA_PLAYER: "POST_DATA_PLAYER",
    GET_DATA_PLAYER: "GET_DATA_PLAYER",
    COMPOSER_PLAYER: "COMPOSER_PLAYER",
    RELOAD: "RELOAD"
}

class AppSocket {
    ws: WebSocket;
    url: string;
    protocols?: [];
    onOpen: any

    constructor(url: string, protocols?: []) {
        this.url = url;
        this.protocols = protocols;
        this.ws = this.connect();
    }

    connect = () => {
        if(this.ws){
            this.ws.removeEventListener("close", () => {});
            this.ws.removeEventListener("error", () => {});
            this.ws.removeEventListener("message", () => {});
            this.ws.removeEventListener("open", () => {});
            
            if(this.ws.readyState == this.ws.OPEN){
                this.ws.close(1000)
            }
        }

        this.ws = new WebSocket(this.url, this.protocols);
        this.ws.binaryType = "arraybuffer";

        this.ws.onclose = (ev) => {
            console.log("close", ev);
            setTimeout(() => this.connect(), 3000)
        }

        this.ws.onerror = (ev) => {
            console.log("onerror", ev)
            // setTimeout(() => this.connect(), 3000)
        }

        this.ws.onopen = (ev) => {
            window.postMessage({
                type: SocketMethods.SOCKET_OPEN
            }, "*")
        }

        this.ws.onmessage = (ev) => {
            window.postMessage({
                type: SocketMethods.SOCKET_MESSAGE,
                data: this.getMessage(ev.data)
            }, "*")
        }

        return this.ws;
    }

    send = (data: string) => {
        try{
            this.ws?.send(new TextEncoder().encode(data))
        } catch(err){

        }
    }

    getMessage = (data: any) => {
        if(data instanceof ArrayBuffer){
            return new TextDecoder().decode(data)
        } else {
            return data
        }
    }
}

export {
    AppSocket,
    SocketMethods
}